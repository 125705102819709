//----------------------------------------------------
//      reset stuff
//----------------------------------------------------
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, hr, main {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
    display: block;
}

audio, canvas, video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden], template {
    display: none;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

blockquote, q {
    quotes: none;
}

q {
    quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before, blockquote:after, q:before, q:after {
    content: "";
    content: none;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

dfn {
    font-style: italic;
}

hr {
    display: block;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    border-top: 1px solid #ccc;
}

pre, code, kbd, samp {
    font-family: monospace, sans-serif;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
}

small {
    font-size: 80%;
}

b, strong, th {
    font-weight: bold;
}

sup, sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

//----------------------------------------------------
//   Forms
//----------------------------------------------------

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0;
    padding: 10px;
}

legend {
    border: 0;
    padding: 0;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

button,
input {
    line-height: normal;
}


button,
select {
    text-transform: none;
}


button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

//----------------------------------------------------
//   Tables
//----------------------------------------------------

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td {
    vertical-align: top;
}

//----------------------------------------------------
//   Embedded content
//----------------------------------------------------

img {
   -ms-interpolation-mode: bicubic;
   border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

//----------------------------------------------------
//   Figures
//----------------------------------------------------

figure {
    margin: 0;
}

//----------------------------------------------------
//   Box sizing defaults to border-box
//----------------------------------------------------

*,
*:before,
*:after {
  box-sizing: border-box;
}
