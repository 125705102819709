//----------------------------------------------------
//      news listings
//----------------------------------------------------
.screen-reader-text {
    @extend .visuallyhidden;
}

.latest-news {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: 1px solid $blue;
        position: relative;

        a {
            display: block;
            height: 100%;
            position: relative;
            color: $blue;
            padding: 28px (28/1000)*100%;
            transition: .4s background-color ease;

            &:hover {
                background-color: $blue;

                .read-more {
                    text-decoration: none;
                }

                time {
                    .time {
                        color: #fff;
                    }
                }

                h3,
                .summary {
                    color: #fff;
                }
            }
        }
    }

    time {
        position: absolute;
        top: 28px;
        left: (28/1000)*100%;
        width: 70px;
        text-align: center;

        .day,
        .month,
        .time {
            display: block;
            line-height: 1;
        }

        .day {
            font-size: 51px;
            color: $red;
        }

        .month {
            font-size: 13px;
            color: $red;
            margin-bottom: 2px;
        }

        .time {
            font-size: 14px;
        }
    }

    .info {
        padding-left: 80px;
        position: relative;
    }

    h3 {
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin: 0 0 10px;
        padding-top: 10px;
        line-height: 1;
    }

    .summary {
        font-size: 12px;
        display: block;
        margin: 0 0 6px 0;
        line-height: 14px;

        p {
            margin: 0;
        }
    }

    .read-more {
        color: $red;
        display: block;
    }

    @include mq($until: mobileLandscape) {
        ul {
            margin-bottom: 10px;
        }

        time {
            width: 55px;

            .day {
                font-size: 36px;
            }

            .month {
                font-size: 11px;
            }

            .time {
                font-size: 11px;
            }
        }

        .info {
            padding-left: 65px;
        }

        h3 {
            padding-top: 5px;
            font-size: 15px;
        }

        .read-more {
            font-size: 11px;
        }
    }
}

//why is wordpress so bad with markup?
//what even is this pagination stuff they output?
//why is it not just a <nav> with a <ul> like most nav?!
.pagination {
    padding: 28px (28/1000)*100%;

    .nav-links {
        @include cf;
    }

    .page-numbers {
        display: block;
        padding: 0 10px;
        float: left;
        font-weight: $font-weight-bold;
    }
}
