//----------------------------------------------------
//      gallery layout
//----------------------------------------------------

.bradley-gallery {
    @include cf;
    position: relative;

    .gallery-main-image {
        clear: both;
        margin-bottom: 15px;

        img {
            vertical-align: bottom;
            width: 100%;
            height: auto;
        }
    }

    .gallery-thumbs {
        list-style: none;
        @include cf;

        li {
            width: (108/480)*100%;
            float: left;
            margin: 0 (15/480)*100% 15px 0;
            padding-top: (108/480)*100%;
            height: 0;
            overflow: visible;
            position: relative;

            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }

        a {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;

            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparentize($red, .15);
                background-image: url(img/plus.png);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 15px 15px;
                opacity: 0;
                transition: .4s opacity ease;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }

        img {
            position: absolute;
            min-width: 100%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            vertical-align: bottom;
        }
    }

    .gallery-loading {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%,0);
        width: 100px;
        height: 100px;
        background-color: #fff;
        opacity: 0;
        transition: .5s opacity ease, .5s z-index ease;
        z-index: -1;

        &.active {
            opacity: 1;
            z-index: 100;
        }

        &:after {
            content: 'Please wait, image loading';
            display: block;
            width: 100%;
            height: 100%;
            background: url(img/loading.gif) 50% 50% no-repeat;
            background-size: 80px 80px;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            text-indent: 110%;
            overflow: hidden;
            white-space: nowrap;
            transition: .5s z-index ease;
        }
    }

    @at-root {
        /*http://tobiasahlin.com/spinkit/*/
        @-webkit-keyframes sk-rotateplane {
            0% { -webkit-transform: perspective(120px) rotateY(0deg) rotateX(0deg) }
            50% { -webkit-transform: perspective(120px) rotateY(180deg) }
            100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
        }

        @keyframes sk-rotateplane {
            0% {
                transform: perspective(120px) rotateX(0deg) rotateY(0deg);
                -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            } 50% {
                transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
                -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            } 100% {
                transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            }
        }
    }
}
