//----------------------------------------------------
//      footer
//----------------------------------------------------

.site-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    .latest-news {
        padding-left: (100/1200)*100%;
        padding-right: (100/1200)*100%;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: $blue;
        flex: 0 1 (763/1200)*100%;
        margin-right: (10/1200)*100%;

        > h2 {
            margin: 0 0 40px;
            line-height: 1;
        }

        .see-news {
            color: #fff;
            font-size: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            margin-bottom: 30px;
        }

        li {
            border-bottom: 1px solid #fff;
            padding-bottom: 20px;
            padding-top: 20px;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            a {
                color: #fff;
                padding: 0;
                min-height: 80px;

                &:hover {
                    .read-more {
                        text-decoration: underline;
                    }
                }
            }
        }

        time {
            top: 0;
            left: 0;

            .time {
                color: #fff;
            }
        }

        h3 {
            color: #fff;
        }

        @include mq($from: mobileLandscape, $until: desktop) {
            padding-left: (60/1200)*100%;
            padding-right: (60/1200)*100%;
        }

        @include mq($until: mobileLandscape) {
            padding-left: (30/1200)*100%;
            padding-right: (30/1200)*100%;
            padding-top: 20px;
            padding-bottom: 20px;
            flex: 0 1 100%;
            margin-right: 0;

            > h2 {
                margin: 0 0 10px;
            }
        }
    }

    .our-clients {
        background-color: #fff;
        text-align: center;
        flex: 0 1 (426/1200)*100%;
        padding-top: 30px;

        p {
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 20px;
            max-width: (254/426)*100%;
            margin: 0 auto 50px;
        }

        ul {
            list-style: none;
            padding-left: (100/426)*100%;
            padding-right: (100/426)*100%;
            margin: 0;
        }

        li {
            clear: both;
            margin-bottom: 50px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            vertical-align: bottom;
            width: 100%;
        }

        @include mq($from: mobileLandscape, $until: desktop) {
            p {
                font-size: 14px;
                line-height: 16px;
                max-width: 80%;
                margin-bottom: 25px;
            }

            ul {
                padding-left: (60/426)*100%;
                padding-right: (60/426)*100%;
            }

            li {
                margin-bottom: 25px;
            }
        }

        @include mq($until: mobileLandscape) {
            flex: 0 1 100%;
            padding-top: 20px;

            p {
                font-size: 16px;
                line-height: 18px;
                max-width: 80%;
            }

            ul {
                padding-left: (40/426)*100%;
                padding-right: (40/426)*100%;
            }

            li {
                margin-bottom: 25px;
            }

            img {
                max-width: 200px;
            }
        }
    }

    .site-info {
        background-color: $blue;
        position: relative;
        clear: both;
        margin-top: 10px;
        padding-left: (100/1200)*100%;
        padding-right: (100/1200)*100%;
        padding-top: 50px;
        padding-bottom: 50px;
        flex: 1 0 100%;

        @include mq($from: tablet, $until: desktop) {
            padding-left: $med-padding-side;
            padding-right: $med-padding-side;
        }

        @include mq($from: mobileLandscape, $until: tablet) {
            padding-left: $med-padding-side;
            padding-right: $med-padding-side;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include mq($until: mobileLandscape) {
            padding-left: $small-padding-side;
            padding-right: $small-padding-side;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .newsletter-signup {
        float: left;
        clear: left;
        width: (326/1200*100%);

        h2 {
            font-size: 25px;
            color: #fff;
            margin: 0 0 5px 0
        }

        p {
            font-size: 12px;
            margin: 0 0 5px 0;
            color: #fff;
        }

        form {
            fieldset {
                padding: 0;
                margin: 0;
                border: 0;
                position: relative;
                @include cf;
            }

            legend {
                display: none;
            }

            label {
                display: none;
            }

            input {
                border: 0;
                height: 36px;
                line-height: 36px;
                padding: 0 10px;
                background-color: #fff;
                font-size: 12px;
                color: $grey;
                width: calc(100% - 42px);
                float: left;
                border: 2px solid #fff;
                transition: .25s border ease;

                &:focus {
                    border: 2px solid $red;
                    outline: 0;
                }
            }

            button {
                border: 0;
                background-color: $red;
                background-image: url(img/tick.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                height: 36px;
                width: 36px;
                float: right;
                text-indent: 125%;
                white-space: nowrap;
                overflow: hidden;
                transition: .4s background-color ease;

                &:hover {
                    background-color: darken($red, 10%);
                }

                &:focus {
                    outline: 0;
                }
            }
        }

        @include mq($from: tablet, $until: desktop) {
            width: 32%;
        }

        @include mq($until: tablet) {
            float: none;
            clear: both;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .footer-nav {
        float: right;
        clear: right;
        @include cf;

        ul {
            list-style: none;
            @include cf;
            padding: 0;
            margin: 0 0 20px 0;
            float: right;
            clear: right;
        }

        li {
            float: left;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            font-size: 11px;

            &:hover {
                text-decoration: underline;
            }
        }

        @include mq($from: tablet, $until: desktop) {
            max-width: 60%;

            ul {
                text-align: right;
            }

            li {
                margin-right: 0;
                margin-left: 10px;
                float: none;
                display: inline-block;
            }
        }

        @include mq($until: tablet) {
            float: none;
            clear: both;
            width: 100%;
            margin-bottom: 10px;

            ul {
                float: none;
            }

            li {
                margin-right: 10px;
            }

            .social-links {
                position: relative;
                float: none;
                clear: both;
                margin-bottom: 0;
                top: auto;
                right: auto;
            }
        }
    }

    .copyright {
        width: 100%;
        float: left;
        clear: both;
        font-size: 10px;
        color: #fff;
        margin: 40px 0 0 0;

        a {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }

        @include mq($until: mobileLandscape) {
            margin-top: 10px;
        }
    }
}
