//----------------------------------------------------
//      phader styles
//----------------------------------------------------

#darth-phader {
    height: 0;
    padding-top: (365/1200)*100%;
    position: relative;
    width: 100%;
    border-bottom: 20px solid $red;
    background-color: $blue;

    .slides,
    .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slide {
        overflow: hidden;

        img {
            vertical-align: bottom;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            transform: translate(-50%,-50%);
        }
    }

    .slide-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: calc(100% - 200px);
        padding: 20px;
        font-size: 31px;
        line-height: 34px;
        font-weight: normal;
        color: #fff;
        text-align: center;
        background-color: transparentize($blue, .14);

        p {
            margin: 0;
        }

        span {
            font-weight: $font-weight-bold;
        }
    }

    nav {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%, 0);
        height: 12px;

        button {
            margin: 0 5px;
            width: 12px;
            height: 12px;
            border: 2px solid #fff;
            padding: 0;
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            border-radius: 50%;
            transition: .25s background-color ease;
            background-color: $blue;

            &:hover {
                background-color: darken($blue, 10%);
            }

            &.active {
                background-color: $red;
            }

            &:focus {
                outline: 0;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        .slide-content {
            font-size: 24px;
            line-height: 26px;
            width: calc(100% - 8.333333%);
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        padding-top: (365/1200)*100%;

        .slide-content {
            font-size: 24px;
            line-height: 26px;
        }
    }

    @include mq($until: tablet) {
        padding-top: (665/1200)*100%;

        .slide-content {
            font-size: 16px;
            line-height: 19px;
            padding: 10px;
            width: calc(100% - 20px);
        }

        nav {
            bottom: 10px;
        }
    }
}

