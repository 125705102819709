//------------------------------------------------------------------------------
//       Helper classes
//-----------------------------------------------------------------------------

//------------------------------------------------------------------------------
// Hide from both screenreaders and browsers: h5bp.com/u
//------------------------------------------------------------------------------

.hidden {
    display: none !important;
    visibility: hidden;
}

//------------------------------------------------------------------------------
// Hide only visually, but have it available for screenreaders: h5bp.com/v
//------------------------------------------------------------------------------

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

//------------------------------------------------------------------------------
// Extends the .visuallyhidden class to allow the element to be focusable
// when navigated to via the keyboard: h5bp.com/p
//------------------------------------------------------------------------------

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

//------------------------------------------------------------------------------
// Hide visually and from screenreaders, but maintain layout
//------------------------------------------------------------------------------

.invisible {
    visibility: hidden;
}

//------------------------------------------------------------------------------
// Clearfix: contain floats
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//------------------------------------------------------------------------------

.cf {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

//------------------------------------------------------------------------------
// For IE 6/7 only
// Include this rule to trigger hasLayout and contain floats.
//------------------------------------------------------------------------------

.cf {
    *zoom: 1;
}
