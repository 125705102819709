//----------------------------------------------------
//      main content area
//----------------------------------------------------

#main {
    position: relative;
    background-color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: $default-padding-side;
    padding-right: $default-padding-side;
    margin-top: 0;
    margin-bottom: 10px;
    border-top: 10px solid $grey-light;
    max-width: 1200px;

    @include mq($from: tablet, $until: desktop) {
        padding-left: $med-padding-side;
        padding-right: $med-padding-side;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @include mq($until: tablet) {
        padding-left: $small-padding-side;
        padding-right: $small-padding-side;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

//----------------------------------------------------
//      page content areas
//----------------------------------------------------

.services-list {
    padding-left: $default-padding-side;
    padding-right: $default-padding-side;
    padding-top: 28px;
    padding-bottom: 28px;
    background-color: $blue-dark;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        @include cf;
    }

    li {
        float: left;
        width: (192/1000)*100%;
        height: 0;
        padding-top: (271/1000)*100%;
        margin-right: (10/1000)*100%;
        position: relative;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-decoration: none;
        overflow: hidden;

        &:hover,
        &:focus {
            .info {
                bottom: 0;
            }
        }
    }

    img {
        vertical-align: bottom;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .info {
        position: absolute;
        height: 0;
        width: 100%;
        padding-top: 100%;
        bottom: (-120/271)*100%;
        transition: .5s bottom ease;
        background-color: rgba(0,0,0,.8);

        h2,
        .description,
        .read-more {
            padding-left: (20/192)*100%;
            padding-right: (20/192)*100%;
        }

        h2 {
            font-size: 18px;
            color: #fff;
            margin: 0 0 0;
            height: 70px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 16px;
            font-weight: $font-weight-regular;
        }

        .description {
            display: block;
            color: #fff;
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 20px;
            position: absolute;
            left: 0;
            top: (100/271)*100%;
        }

        .read-more {
            display: block;
            color: $red;
            font-size: 14px;
            position: absolute;
            left: 0;
            bottom: 20px;
        }
    }

    &.landing {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff;

        li {
            width: (326/1000)*100%;
            padding-top: (326/1000)*100%;
            margin-right: (11/1000)*100%;
            margin-bottom: 10px;

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }
        }

        .info {
            padding-top: 50%;
            bottom: (-110/326)*100%;

            h2,
            .description,
            .read-more {
                padding-left: (20/326)*100%;
                padding-right: (20/326)*100%;
            }
        }
    }

    @include mq($from: tablet, $until: desktop) {
        padding-left: $med-padding-side;
        padding-right: $med-padding-side;

        .info {
            h2 {
                padding-top: 10px;
                font-size: 13px;
                line-height: 1.2;
            }

            .description {
                line-height: 1.1;
                font-size: 11px;
            }

            .read-more {
                bottom: 10px;
            }
        }
    }

    @include mq($until: tablet) {
        padding-left: $small-padding-side;
        padding-right: $small-padding-side;

        li {
            width: 49%;
            padding-top: calc(50% - 50px);
            margin-right: 2%;
            margin-bottom: 10px;

            &:nth-child(2n+2),
            &:last-child {
                margin-right: 0;
            }

            .info {
                padding-top: 60%;
                bottom: -50%;

                h2 {
                    font-size: 11px;
                    line-height: 12px;
                    padding-top: 8px;
                    height: 50px;
                }

                .description {
                    display: none;
                }

                .read-more {
                    font-size: 11px;
                    bottom: 10px;
                }
            }
        }

        &.landing {
            li {
                &:nth-child(2n+2) {
                    margin-right: (11/1000)*100%;
                }

                .info {
                    .description {
                        display: none;
                    }
                }
            }
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        li {
            .info {
                bottom: -52%;
            }
        }

        &.landing {
            li {
                .info {
                    h2 {
                        padding-top: 6px;
                    }
                }
            }
        }
    }

    @include mq($until: mobileLandscape) {
        li {
            .info {
                h2 {
                    padding-top: 6px;
                }

                h2,
                .description,
                .read-more {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        &.landing {
            li {
                width: 49%;
                padding-top: 50%;
                margin-right: 2%;
                margin-bottom: 10px;

                &:nth-child(2n+2) {
                    margin-right: 0;
                }

                &:nth-of-type(3n+3) {
                    margin-right: 2%;
                }

                .info {
                    bottom: (-80/320)*100%;

                    h2 {
                        padding-top: 6px;
                    }

                    .decsription {
                        display: block;
                    }
                }
            }
        }
    }
}

//----------------------------------------------------
//      services list text style
//----------------------------------------------------

.services-list-text {
    > ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;

        li {
            flex: 0 1 (326/1000)*100%;
            margin-bottom: 10px;
            margin-right: (10/1000)*100%;
            min-height: 130px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        a {
            display: block;
            height: 100%;
            position: relative;
            background-color: $blue;
            color: #fff;
            padding: (20/326)*100%;
            position: relative;
            transition: .4s background-color ease;

            h2 {
                font-size: 18px;
                margin: 0 0 5px 0;
                color: #fff;
            }

            .description {
                font-size: 12px;
                margin-bottom: 50px;
                display: block;
            }

            .read-more {
                font-size: 14px;
                color: $red;
                position: absolute;
                bottom: 20px;
                left: 20px;
            }

            &:hover {
                background-color: $red;

                .read-more {
                    color: $blue;
                }
            }
        }
    }

    @include mq($until: desktop) {
        > ul {
            a {
                h2 {
                    font-size: 16px;
                }

                .read-more {
                    font-size: 12px;
                }
            }
        }
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        > ul {
            li {
                flex: 0 1 (379/768)*100%;
                margin-right: (10/768)*100%;

                &:nth-child(3n) {
                    margin-right: (10/768)*100%;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include mq($until: mobileLandscape) {
        > ul {
            li {
                flex: 0 1 100%;
                margin-right: 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
}

//----------------------------------------------------
//      article and aside blocks
//----------------------------------------------------

.content-area {
    @include cf;

    article {
        float: left;
        width: (600/1000)*100%;

        &.latest-news {
            width: 100%;
            float: none;
            clear: both;
        }
    }

    aside {
        float: right;
        width: (326/1000)*100%;

        img {
            vertical-align: bottom;
            width: 100%;
        }
    }

    &.equal-split {
        article,
        aside {
            width: (485/1000)*100%;
        }
    }

    @include mq($until: mobileLandscape) {
        article,
        aside {
            float: none;
            clear: both;
            width: 100%;
        }

        article {
            margin-bottom: 20px;
        }

        &.equal-split {
            article,
            aside {
                width: 100%;
            }
        }
    }
}


//----------------------------------------------------
//      recent case studies
//----------------------------------------------------

.recent-case-studies {
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        @include cf;
    }

    li {
        float: left;
        margin-right: (10/1000)*100%;
        width: (326/1000)*100%;
        height: 0;
        padding-top: (218/1000)*100%;
        position: relative;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        &:hover,
        &:focus {
            .info {
                bottom: 0;
            }
        }
    }

    img {
        vertical-align: bottom;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .info {
        position: absolute;
        left: 0;
        height: 0;
        padding-top: (218/326)*100%;
        width: (192/326)*100%;
        background-color: transparentize($red,.1);
        bottom: (-148/218)*100%;
        transition: .5s bottom ease;

        h2,
        .description,
        .read-more {
            color: #fff;
            position: absolute;
            width: 100%;
        }

        h2 {
            height: 70px;
            top: 0;
            margin: 0;
            padding: 16px 15px 0;
            line-height: 1;
            font-size: 18px;
            font-weight: $font-weight-regular;
        }

        .description {
            top: 70px;
            padding: 0 15px;
        }

        .read-more {
            position: absolute;
            bottom: 10px;
            padding: 0 15px;
        }
    }

    @include mq($from: mobileLandscape, $until: desktop) {
        .info {
            width: 100%;

            h2 {
                font-size: 16px;
            }

            .description {
                font-size: 12px;
            }
        }
    }

    @include mq($until: mobileLandscape) {
        li {
            margin-right: 0;
            width: 100%;
            padding-top: 70%;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .info {
            width: 100%;

            h2 {
                font-size: 16px;
            }

            .description {
                font-size: 12px;
            }
        }
    }
}

//----------------------------------------------------
//      accreditations
//----------------------------------------------------

.accreditations {
    padding-top: 60px;
    padding-bottom: 60px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        flex: 0 0 auto;

        &:last-child {
            margin-right: 0;
        }
    }

    img {
        vertical-align: bottom;
        width: 100%;
    }

    @include mq($until: tablet) {
        padding-top: 30px;
        padding-bottom: 10px;

        ul {
            justify-content: center;
        }

        li {
            flex: 0 0 60px;
            margin: 0 10px 10px;
        }
    }
}
