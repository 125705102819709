//----------------------------------------------------
//      header
//----------------------------------------------------

.site-header {
    background-color: $blue;

    .header-top {
        background-color: $blue;
        padding-left: $default-padding-side;
        padding-right: $default-padding-side;
        padding-top: 32px;
        padding-bottom: 32px;
        @include cf;
    }

    .logo {
        @include imgreplace(240px, 96px, 'img/logo.png');
        float: left;
        margin: 0;
        transition: .4s opacity ease;

        &:hover {
            opacity: .6;
        }
    }

    .menu-toggle {
        display: none;
    }

    .main-nav {
        float: right;
        clear: right;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            @include cf;

            > li {
                float: left;
                margin-right: 20px;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }

                > ul {
                    position: absolute;
                    top: 40px;
                    right: 0;
                    display: block;
                    width: 190px;
                    padding: 12px;
                    background-color: #fff;
                    opacity: 0;
                    z-index: -1;
                    transition: .4s opacity ease, .4s z-index ease;
                    box-shadow: 0 0 10px 0 rgba(0,0,0,.8);

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: -14px;
                        right: 20px;
                        border-bottom: 14px solid #fff;
                        border-left: 20px solid transparent;
                        border-right: 20px solid transparent;
                    }

                    li {
                        float: none;
                        width: 100%;
                        margin: 0;
                    }

                    ul {
                        display: none;
                    }

                    a {
                        display: block;
                        color: $blue;
                        border: 0;
                        padding: 5px 0;

                        &:after {
                            display: none;
                        }

                        &:hover {
                            color: $red;

                            &:after {
                                display: none;
                            }
                            border: 0;
                        }
                    }
                }

                &:hover {
                    > ul {
                        opacity: 1;
                        z-index: 1000;
                    }
                }
            }
        }

        .current-menu-item,
        .current-page-ancestor {
            a {
                border-bottom-color: $red;

                &:hover {
                    color: $red;
                }
            }
        }

        a {
            color: #fff;
            text-decoration: none;
            padding-bottom: 8px;
            font-size: 13px;
            transition: .25s color ease;
            position: relative;

            &:after {
                content: '';
                display: block;
                opacity: 0;
                background-color: $red;
                transition: .4s opacity ease;
                width: 100%;
                height: 3px;
                position: absolute;
                bottom: 2px;
                left: 0;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .search-contact {
        background-color: $blue-dark;
        padding-left: $default-padding-side;
        padding-right: $default-padding-side;
        padding-top: 25px;
        padding-bottom: 25px;
        @include cf;
        border-bottom: 10px solid #050922;
    }

    .contact-details {
        float: left;
        clear: left;
        list-style: none;
        padding: 0;
        margin: 0;
        color: #fff;

        li {
            float: left;
            margin-right: 20px;
            line-height: 26px;

            &:last-child {
                margin-right: 0;
            }
        }

        span {
            color: $red;
            text-transform: uppercase;
            display: inline-block;
            padding-right: 4px;
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .site-search {
        float: right;
        clear: right;

        fieldset {
            border: 0;
            padding: 0;
            margin: 0;
            position: relative;
        }

        legend {
            display: none;
        }

        label {
            display: none;
        }

        input {
            background-color: #fff;
            border: 3px solid #fff;
            padding: 6px;
            height: 26px;
            font-family: $font-sans;
            font-size: 13px;
            color: $blue;
            transition: .25s border ease;
            width: 246px;
            @include placeholder($blue);

            &:focus {
                @include placeholder(#fff);
                border-color: $red;
                outline: 0;
            }
        }

        button {
            border: 0;
            background: url(img/search-button.png) 50% 50% no-repeat;
            background-size: 18px auto;
            padding: 0;
            margin: 0;
            text-indent: 115%;
            white-space: nowrap;
            overflow: hidden;
            width: 26px;
            height: 26px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    @include mq($from: tablet, $until: desktop) {
        .header-top,
        .search-contact {
            padding-left: $med-padding-side;
            padding-right: $med-padding-side;
        }

        .logo {
            width: 180px;
            height: 72px;
        }

        .main-nav {
            a {
                font-size: 12px;
            }
        }
    }

    @include mq($until: tablet) {
        .header-top,
        .search-contact {
            padding-left: $small-padding-side;
            padding-right: $small-padding-side;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .search-contact {
            .contact-details {
                float: none;
                clear: both;

                li {
                    line-height: 18px;
                    font-size: 14px;
                }
            }

            .site-search {
                float: none;
                clear: both;
                width: 100%;
                padding-top: 10px;

                input {
                    width: 100%;
                }
            }
        }

        .logo {
            width: 140px;
            height: 56px;
            float: none;
            clear: both;
            margin: 0 auto;
        }

        .menu-toggle {
            display: block;
            position: absolute;
            top: 35px;
            left: 10px;
            border: 0;
            background-color: #fff;
            padding: 6px 10px;
            margin: 0;
        }

        .main-nav {
            float: none;
            clear: both;

            > ul {
                display: none;

                > li {
                    float: none;
                    clear: both;
                    margin-right: 0;

                    a {
                        display: block;
                        padding: 10px;

                        &:after {
                            display: none;
                        }

                        &:hover {
                            background-color: $blue-dark;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    > ul {
                        padding: 0;
                        opacity: 1;
                        position: relative;
                        top: auto;
                        left: auto;
                        width: 100%;
                        z-index: 1;
                        box-shadow: none;
                        background-color: $blue-dark;

                        &:before {
                            display: none;
                        }

                        a {
                            color: #fff;
                            padding: 10px;

                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
}

//----------------------------------------------------
//      social media links
//----------------------------------------------------

.social-links {
    list-style: none;
    padding: 0;
    margin: 10px 0 30px 0;
    @include cf;
    float: right;
    clear: right;

    li {
        float: left;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: block;
        width: 16px;
        height: 16px;
        text-indent: 115%;
        overflow: hidden;
        white-space: nowrap;
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        transition: .4s opacity ease;

        &:hover {
            opacity: .6;
        }
    }

    .twitter {
        background-image: url(img/twitter.png);
    }

    .facebook {
        background-image: url(img/facebook.png);
    }

    @include mq($until: tablet) {
        float: none;
        clear: none;
        position: absolute;
        top: 45px;
        right: 10px;
        margin: 0;
    }
}

//----------------------------------------------------
//      lead images
//----------------------------------------------------

.lead-image {
    position: relative;
    height: 0;
    padding-top: (324/1200)*100%;
    overflow: hidden;
    background: url(img/slides/slide-1.jpg) 50% 50% no-repeat;
    background-size: cover;

    > img {
        vertical-align: bottom;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        min-width: 100%;
        min-height: 100%;
    }

    @include mq($from: wide) {
        padding-top: (324/1440)*100%;
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        padding-top: (324/768)*100%;
    }

    @include mq($until: mobileLandscape) {
        padding-top: 50%;
    }
}

.lead-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px $default-padding-side 20px;
    background-color: transparentize($red, .15);

    h1 {
        color: #fff;
        margin: 0;
        font-size: 25px;
        font-weight: $font-weight-bold;
        line-height: 1;
    }

    @include mq($from: tablet, $until: desktop) {
        padding: 20px $med-padding-side;
    }

    @include mq($from: mobileLandscape, $until: tablet) {
        padding: 10px;

        h1 {
            font-size: 18px;
        }
    }

    @include mq($until: mobileLandscape) {
        padding: 10px;

        h1 {
            font-size: 16px;
        }
    }
}
