//----------------------------------------------------
//      basic typography
//----------------------------------------------------

body {
    font-family: $font-sans;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: $line-height-base;
    color: $text-color;
    background-color: $grey-light;
}

p {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;

    margin-top: 20px;
    margin-bottom: 20px;

    color: $red;

    &:first-of-type {
        margin-top: 0;
    }
}

@each $header, $size in (h1: $font-size-h1, h2: $font-size-h2, h3: $font-size-h3, h4: $font-size-h4, h5: $font-size-h5, h6: $font-size-h6) {
    #{$header} {
        font-size: $size;
    }
}

h1 {
    margin-top: 0;
    margin-bottom: 20px;
}

@include mq($from: mobileLandscape, $until: tablet) {
    @each $header, $size in (h1: $font-size-h1 - 4px, h2: $font-size-h2 - 4px, h3: $font-size-h3 - 4px, h4: $font-size-h4 - 2px, h5: $font-size-h5 - 2px, h6: $font-size-h6 - 2px) {
        #{$header} {
            font-size: $size;
        }
    }

    body {
        font-size: $font-size - 1px;
    }
}

@include mq($until: mobileLandscape) {
    @each $header, $size in (h1: $font-size-h1 - 6px, h2: $font-size-h2 - 6px, h3: $font-size-h3 - 6px, h4: $font-size-h4 - 4px, h5: $font-size-h5 - 4px, h6: $font-size-h6 - 4px) {
        #{$header} {
            font-size: $size;
        }
    }

    body {
        font-size: $font-size - 2px;
    }
}

//----------------------------------------------------
//  other base styles
//----------------------------------------------------

a {
    text-decoration: none;
    color: $link-color;

    &:hover {
        color: $link-color-hover;
    }
}

.highlight {
    color: $red;
}

//----------------------------------------------------
//      container
//----------------------------------------------------

.contain {
    max-width: 1200px;
    margin: 0 auto;
    @include cf;
    position: relative;
}

//----------------------------------------------------
//      contact form
//      this is really hacky
//      I'm overwriting some awful markup choices
//      Don't judge me
//----------------------------------------------------

.wpcf7 {
    form {
        br {
            display: none;
        }

        div {
            @include cf;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        label {
            display: block;
            clear: both;
            margin: 0 0 5px 0;
        }

        input,
        textarea {
            width: 80%;
            border: 1px solid $blue;
            padding: 10px;
            font-size: $font-size;
            font-family: $font-sans;
            @include placeholder($blue);

            &:focus {
                outline: 0;
                border-color: $red;
            }
        }

        input {
            &[type=submit] {
                background: $red;
                color: #fff;
                transition: .4s background-color ease;
                border: 0;

                &:focus {
                    outline: 0;
                }

                &:hover {
                    background: darken($red, 10%);
                }
            }
        }

        textarea {
            height: 100px;
        }
    }
}
