/*
Theme Name: Bradley Refrigeration 2015
Author: James Lowe/Phil Steer/Integrated Arts
Author URI:
Description: An almost ground up theme for Bradley Refrigeration
Version: 1.0
License: Copyright 2015 Bradley Refrigeration Ltd All Rights Reserved
Template: BradleyRefrigeration
*/

// breakpoints mixin: https://github.com/sass-mq/sass-mq
// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

$mq-breakpoints: (
    mobile:  320px,
    mobileLandscape: 480px,
    tablet:  768px,
    desktop: 1024px,
    wide:    1280px,
);

// $mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
//$mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide);

@import "_mq";
@import "_mixins";

//----------------------------------------------------
// Colours
//----------------------------------------------------

$blue: #2b2d3a;
$blue-dark: #212332;
$red: #ee3e34;
$grey-light: #ededed;
$grey: #727272;

$link-color: $red;
$link-color-hover: $blue-dark;
$text-color: $blue;

//----------------------------------------------------
//      handy things
//----------------------------------------------------

$default-padding-side: 100px;
$med-padding-side: (50/1200)*100%;
$small-padding-side: 10px;

//----------------------------------------------------
//  fonts
//----------------------------------------------------

@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);

$font-sans: 'Montserrat', 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;
$font-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-mono: Monaco, Menlo, Consolas, 'Courier New', monospace !default;
$font-heading: 'Montserrat', 'Helvetica Neue', helvetica, arial, verdana, sans-serif !default;

//----------------------------------------------------
//  base typography
//----------------------------------------------------

$font-size: 14px;
$font-size-h1: 30px;
$font-size-h2: 26px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;

$line-height-base: 1.4;

$font-weight-light: lighter;
$font-weight-regular: 400;
$font-weight-bold: 700;

// third party
@import "_helpers";
@import "_resetalize";

// the site
@import "_base";
@import "_header";
@import "_footer";
@import "_phader";
@import "_content-blocks";
@import "_news";
@import "_gallery";
