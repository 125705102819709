/*
Theme Name: Bradley Refrigeration 2015
Author: James Lowe/Phil Steer/Integrated Arts
Author URI:
Description: An almost ground up theme for Bradley Refrigeration
Version: 1.0
License: Copyright 2015 Bradley Refrigeration Ltd All Rights Reserved
Template: BradleyRefrigeration
*/
@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700);
.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden, .screen-reader-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.focusable.screen-reader-text:active,
.visuallyhidden.focusable:focus, .focusable.screen-reader-text:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.cf:before,
.cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  *zoom: 1; }

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, hr, main {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

blockquote, q {
  quotes: none; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

dfn {
  font-style: italic; }

hr {
  display: block;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  border-top: 1px solid #ccc; }

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

small {
  font-size: 80%; }

b, strong, th {
  font-weight: bold; }

sup, sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0;
  padding: 10px; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-family: "Montserrat", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #2b2d3a;
  background-color: #ededed; }

p {
  margin-bottom: 20px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ee3e34; }
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type,
  h6:first-of-type {
    margin-top: 0; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 26px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

h1 {
  margin-top: 0;
  margin-bottom: 20px; }

@media (min-width: 30em) and (max-width: 47.99em) {
  h1 {
    font-size: 26px; }
  h2 {
    font-size: 22px; }
  h3 {
    font-size: 18px; }
  h4 {
    font-size: 16px; }
  h5 {
    font-size: 14px; }
  h6 {
    font-size: 12px; }
  body {
    font-size: 13px; } }

@media (max-width: 29.99em) {
  h1 {
    font-size: 24px; }
  h2 {
    font-size: 20px; }
  h3 {
    font-size: 16px; }
  h4 {
    font-size: 14px; }
  h5 {
    font-size: 12px; }
  h6 {
    font-size: 10px; }
  body {
    font-size: 12px; } }

a {
  text-decoration: none;
  color: #ee3e34; }
  a:hover {
    color: #212332; }

.highlight {
  color: #ee3e34; }

.contain {
  max-width: 1200px;
  margin: 0 auto;
  position: relative; }
  .contain:before,
  .contain:after {
    content: " ";
    display: table; }
  .contain:after {
    clear: both; }

.wpcf7 form br {
  display: none; }

.wpcf7 form div {
  margin-bottom: 20px; }
  .wpcf7 form div:before,
  .wpcf7 form div:after {
    content: " ";
    display: table; }
  .wpcf7 form div:after {
    clear: both; }
  .wpcf7 form div:last-of-type {
    margin-bottom: 0; }

.wpcf7 form label {
  display: block;
  clear: both;
  margin: 0 0 5px 0; }

.wpcf7 form input,
.wpcf7 form textarea {
  width: 80%;
  border: 1px solid #2b2d3a;
  padding: 10px;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", helvetica, arial, verdana, sans-serif; }
  .wpcf7 form input:-moz-placeholder,
  .wpcf7 form textarea:-moz-placeholder {
    color: #2b2d3a; }
  .wpcf7 form input::-moz-placeholder,
  .wpcf7 form textarea::-moz-placeholder {
    color: #2b2d3a; }
  .wpcf7 form input:-ms-input-placeholder,
  .wpcf7 form textarea:-ms-input-placeholder {
    color: #2b2d3a; }
  .wpcf7 form input::-webkit-input-placeholder,
  .wpcf7 form textarea::-webkit-input-placeholder {
    color: #2b2d3a; }
  .wpcf7 form input:focus,
  .wpcf7 form textarea:focus {
    outline: 0;
    border-color: #ee3e34; }

.wpcf7 form input[type=submit] {
  background: #ee3e34;
  color: #fff;
  transition: .4s background-color ease;
  border: 0; }
  .wpcf7 form input[type=submit]:focus {
    outline: 0; }
  .wpcf7 form input[type=submit]:hover {
    background: #dd1d12; }

.wpcf7 form textarea {
  height: 100px; }

.site-header {
  background-color: #2b2d3a; }
  .site-header .header-top {
    background-color: #2b2d3a;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 32px;
    padding-bottom: 32px; }
    .site-header .header-top:before,
    .site-header .header-top:after {
      content: " ";
      display: table; }
    .site-header .header-top:after {
      clear: both; }
  .site-header .logo {
    display: block;
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
    height: 96px;
    background-color: transparent;
    background-image: url("img/logo.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin: 0;
    transition: .4s opacity ease; }
    .site-header .logo:hover {
      opacity: .6; }
  .site-header .menu-toggle {
    display: none; }
  .site-header .main-nav {
    float: right;
    clear: right; }
    .site-header .main-nav ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .site-header .main-nav ul:before,
      .site-header .main-nav ul:after {
        content: " ";
        display: table; }
      .site-header .main-nav ul:after {
        clear: both; }
      .site-header .main-nav ul > li {
        float: left;
        margin-right: 20px;
        position: relative; }
        .site-header .main-nav ul > li:last-child {
          margin-right: 0; }
        .site-header .main-nav ul > li > ul {
          position: absolute;
          top: 40px;
          right: 0;
          display: block;
          width: 190px;
          padding: 12px;
          background-color: #fff;
          opacity: 0;
          z-index: -1;
          transition: 0.4s opacity ease, 0.4s z-index ease;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8); }
          .site-header .main-nav ul > li > ul:before {
            content: '';
            display: block;
            position: absolute;
            top: -14px;
            right: 20px;
            border-bottom: 14px solid #fff;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent; }
          .site-header .main-nav ul > li > ul li {
            float: none;
            width: 100%;
            margin: 0; }
          .site-header .main-nav ul > li > ul ul {
            display: none; }
          .site-header .main-nav ul > li > ul a {
            display: block;
            color: #2b2d3a;
            border: 0;
            padding: 5px 0; }
            .site-header .main-nav ul > li > ul a:after {
              display: none; }
            .site-header .main-nav ul > li > ul a:hover {
              color: #ee3e34;
              border: 0; }
              .site-header .main-nav ul > li > ul a:hover:after {
                display: none; }
        .site-header .main-nav ul > li:hover > ul {
          opacity: 1;
          z-index: 1000; }
    .site-header .main-nav .current-menu-item a,
    .site-header .main-nav .current-page-ancestor a {
      border-bottom-color: #ee3e34; }
      .site-header .main-nav .current-menu-item a:hover,
      .site-header .main-nav .current-page-ancestor a:hover {
        color: #ee3e34; }
    .site-header .main-nav a {
      color: #fff;
      text-decoration: none;
      padding-bottom: 8px;
      font-size: 13px;
      transition: .25s color ease;
      position: relative; }
      .site-header .main-nav a:after {
        content: '';
        display: block;
        opacity: 0;
        background-color: #ee3e34;
        transition: .4s opacity ease;
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: 2px;
        left: 0; }
      .site-header .main-nav a:hover:after {
        opacity: 1; }
  .site-header .search-contact {
    background-color: #212332;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 10px solid #050922; }
    .site-header .search-contact:before,
    .site-header .search-contact:after {
      content: " ";
      display: table; }
    .site-header .search-contact:after {
      clear: both; }
  .site-header .contact-details {
    float: left;
    clear: left;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #fff; }
    .site-header .contact-details li {
      float: left;
      margin-right: 20px;
      line-height: 26px; }
      .site-header .contact-details li:last-child {
        margin-right: 0; }
    .site-header .contact-details span {
      color: #ee3e34;
      text-transform: uppercase;
      display: inline-block;
      padding-right: 4px; }
    .site-header .contact-details a {
      color: #fff;
      text-decoration: none; }
      .site-header .contact-details a:hover {
        text-decoration: underline; }
  .site-header .site-search {
    float: right;
    clear: right; }
    .site-header .site-search fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      position: relative; }
    .site-header .site-search legend {
      display: none; }
    .site-header .site-search label {
      display: none; }
    .site-header .site-search input {
      background-color: #fff;
      border: 3px solid #fff;
      padding: 6px;
      height: 26px;
      font-family: "Montserrat", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
      font-size: 13px;
      color: #2b2d3a;
      transition: .25s border ease;
      width: 246px; }
      .site-header .site-search input:-moz-placeholder {
        color: #2b2d3a; }
      .site-header .site-search input::-moz-placeholder {
        color: #2b2d3a; }
      .site-header .site-search input:-ms-input-placeholder {
        color: #2b2d3a; }
      .site-header .site-search input::-webkit-input-placeholder {
        color: #2b2d3a; }
      .site-header .site-search input:focus {
        border-color: #ee3e34;
        outline: 0; }
        .site-header .site-search input:focus:-moz-placeholder {
          color: #fff; }
        .site-header .site-search input:focus::-moz-placeholder {
          color: #fff; }
        .site-header .site-search input:focus:-ms-input-placeholder {
          color: #fff; }
        .site-header .site-search input:focus::-webkit-input-placeholder {
          color: #fff; }
    .site-header .site-search button {
      border: 0;
      background: url(img/search-button.png) 50% 50% no-repeat;
      background-size: 18px auto;
      padding: 0;
      margin: 0;
      text-indent: 115%;
      white-space: nowrap;
      overflow: hidden;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 0;
      right: 0; }
  @media (min-width: 48em) and (max-width: 63.99em) {
    .site-header .header-top,
    .site-header .search-contact {
      padding-left: 4.16667%;
      padding-right: 4.16667%; }
    .site-header .logo {
      width: 180px;
      height: 72px; }
    .site-header .main-nav a {
      font-size: 12px; } }
  @media (max-width: 47.99em) {
    .site-header .header-top,
    .site-header .search-contact {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      padding-bottom: 20px; }
    .site-header .search-contact .contact-details {
      float: none;
      clear: both; }
      .site-header .search-contact .contact-details li {
        line-height: 18px;
        font-size: 14px; }
    .site-header .search-contact .site-search {
      float: none;
      clear: both;
      width: 100%;
      padding-top: 10px; }
      .site-header .search-contact .site-search input {
        width: 100%; }
    .site-header .logo {
      width: 140px;
      height: 56px;
      float: none;
      clear: both;
      margin: 0 auto; }
    .site-header .menu-toggle {
      display: block;
      position: absolute;
      top: 35px;
      left: 10px;
      border: 0;
      background-color: #fff;
      padding: 6px 10px;
      margin: 0; }
    .site-header .main-nav {
      float: none;
      clear: both; }
      .site-header .main-nav > ul {
        display: none; }
        .site-header .main-nav > ul > li {
          float: none;
          clear: both;
          margin-right: 0; }
          .site-header .main-nav > ul > li a {
            display: block;
            padding: 10px; }
            .site-header .main-nav > ul > li a:after {
              display: none; }
            .site-header .main-nav > ul > li a:hover {
              background-color: #212332; }
              .site-header .main-nav > ul > li a:hover:after {
                display: none; }
          .site-header .main-nav > ul > li > ul {
            padding: 0;
            opacity: 1;
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            z-index: 1;
            box-shadow: none;
            background-color: #212332; }
            .site-header .main-nav > ul > li > ul:before {
              display: none; }
            .site-header .main-nav > ul > li > ul a {
              color: #fff;
              padding: 10px; }
              .site-header .main-nav > ul > li > ul a:hover {
                color: #ee3e34; } }

.social-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 30px 0;
  float: right;
  clear: right; }
  .social-links:before,
  .social-links:after {
    content: " ";
    display: table; }
  .social-links:after {
    clear: both; }
  .social-links li {
    float: left;
    margin-right: 10px; }
    .social-links li:last-child {
      margin-right: 0; }
  .social-links a {
    display: block;
    width: 16px;
    height: 16px;
    text-indent: 115%;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .4s opacity ease; }
    .social-links a:hover {
      opacity: .6; }
  .social-links .twitter {
    background-image: url(img/twitter.png); }
  .social-links .facebook {
    background-image: url(img/facebook.png); }
  @media (max-width: 47.99em) {
    .social-links {
      float: none;
      clear: none;
      position: absolute;
      top: 45px;
      right: 10px;
      margin: 0; } }

.lead-image {
  position: relative;
  height: 0;
  padding-top: 27%;
  overflow: hidden;
  background: url(img/slides/slide-1.jpg) 50% 50% no-repeat;
  background-size: cover; }
  .lead-image > img {
    vertical-align: bottom;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%; }
  @media (min-width: 80em) {
    .lead-image {
      padding-top: 22.5%; } }
  @media (min-width: 30em) and (max-width: 47.99em) {
    .lead-image {
      padding-top: 42.1875%; } }
  @media (max-width: 29.99em) {
    .lead-image {
      padding-top: 50%; } }

.lead-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 100px 20px;
  background-color: rgba(238, 62, 52, 0.85); }
  .lead-title h1 {
    color: #fff;
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    line-height: 1; }
  @media (min-width: 48em) and (max-width: 63.99em) {
    .lead-title {
      padding: 20px 4.16667%; } }
  @media (min-width: 30em) and (max-width: 47.99em) {
    .lead-title {
      padding: 10px; }
      .lead-title h1 {
        font-size: 18px; } }
  @media (max-width: 29.99em) {
    .lead-title {
      padding: 10px; }
      .lead-title h1 {
        font-size: 16px; } }

.site-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch; }
  .site-footer .latest-news {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #2b2d3a;
    flex: 0 1 63.58333%;
    margin-right: 0.83333%; }
    .site-footer .latest-news > h2 {
      margin: 0 0 40px;
      line-height: 1; }
    .site-footer .latest-news .see-news {
      color: #fff;
      font-size: 16px; }
      .site-footer .latest-news .see-news:hover {
        text-decoration: underline; }
    .site-footer .latest-news ul {
      margin-bottom: 30px; }
    .site-footer .latest-news li {
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      padding-top: 20px; }
      .site-footer .latest-news li:first-child {
        padding-top: 0; }
      .site-footer .latest-news li:last-child {
        border-bottom: 0;
        padding-bottom: 0; }
      .site-footer .latest-news li a {
        color: #fff;
        padding: 0;
        min-height: 80px; }
        .site-footer .latest-news li a:hover .read-more {
          text-decoration: underline; }
    .site-footer .latest-news time {
      top: 0;
      left: 0; }
      .site-footer .latest-news time .time {
        color: #fff; }
    .site-footer .latest-news h3 {
      color: #fff; }
    @media (min-width: 30em) and (max-width: 63.99em) {
      .site-footer .latest-news {
        padding-left: 5%;
        padding-right: 5%; } }
    @media (max-width: 29.99em) {
      .site-footer .latest-news {
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-top: 20px;
        padding-bottom: 20px;
        flex: 0 1 100%;
        margin-right: 0; }
        .site-footer .latest-news > h2 {
          margin: 0 0 10px; } }
  .site-footer .our-clients {
    background-color: #fff;
    text-align: center;
    flex: 0 1 35.5%;
    padding-top: 30px; }
    .site-footer .our-clients p {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      max-width: 59.62441%;
      margin: 0 auto 50px; }
    .site-footer .our-clients ul {
      list-style: none;
      padding-left: 23.47418%;
      padding-right: 23.47418%;
      margin: 0; }
    .site-footer .our-clients li {
      clear: both;
      margin-bottom: 50px; }
      .site-footer .our-clients li:last-child {
        margin-bottom: 0; }
    .site-footer .our-clients img {
      vertical-align: bottom;
      width: 100%; }
    @media (min-width: 30em) and (max-width: 63.99em) {
      .site-footer .our-clients p {
        font-size: 14px;
        line-height: 16px;
        max-width: 80%;
        margin-bottom: 25px; }
      .site-footer .our-clients ul {
        padding-left: 14.08451%;
        padding-right: 14.08451%; }
      .site-footer .our-clients li {
        margin-bottom: 25px; } }
    @media (max-width: 29.99em) {
      .site-footer .our-clients {
        flex: 0 1 100%;
        padding-top: 20px; }
        .site-footer .our-clients p {
          font-size: 16px;
          line-height: 18px;
          max-width: 80%; }
        .site-footer .our-clients ul {
          padding-left: 9.38967%;
          padding-right: 9.38967%; }
        .site-footer .our-clients li {
          margin-bottom: 25px; }
        .site-footer .our-clients img {
          max-width: 200px; } }
  .site-footer .site-info {
    background-color: #2b2d3a;
    position: relative;
    clear: both;
    margin-top: 10px;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
    padding-top: 50px;
    padding-bottom: 50px;
    flex: 1 0 100%; }
    @media (min-width: 48em) and (max-width: 63.99em) {
      .site-footer .site-info {
        padding-left: 4.16667%;
        padding-right: 4.16667%; } }
    @media (min-width: 30em) and (max-width: 47.99em) {
      .site-footer .site-info {
        padding-left: 4.16667%;
        padding-right: 4.16667%;
        padding-top: 30px;
        padding-bottom: 30px; } }
    @media (max-width: 29.99em) {
      .site-footer .site-info {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px; } }
  .site-footer .newsletter-signup {
    float: left;
    clear: left;
    width: 27.16667%; }
    .site-footer .newsletter-signup h2 {
      font-size: 25px;
      color: #fff;
      margin: 0 0 5px 0; }
    .site-footer .newsletter-signup p {
      font-size: 12px;
      margin: 0 0 5px 0;
      color: #fff; }
    .site-footer .newsletter-signup form fieldset {
      padding: 0;
      margin: 0;
      border: 0;
      position: relative; }
      .site-footer .newsletter-signup form fieldset:before,
      .site-footer .newsletter-signup form fieldset:after {
        content: " ";
        display: table; }
      .site-footer .newsletter-signup form fieldset:after {
        clear: both; }
    .site-footer .newsletter-signup form legend {
      display: none; }
    .site-footer .newsletter-signup form label {
      display: none; }
    .site-footer .newsletter-signup form input {
      border: 0;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      background-color: #fff;
      font-size: 12px;
      color: #727272;
      width: calc(100% - 42px);
      float: left;
      border: 2px solid #fff;
      transition: .25s border ease; }
      .site-footer .newsletter-signup form input:focus {
        border: 2px solid #ee3e34;
        outline: 0; }
    .site-footer .newsletter-signup form button {
      border: 0;
      background-color: #ee3e34;
      background-image: url(img/tick.png);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 36px;
      width: 36px;
      float: right;
      text-indent: 125%;
      white-space: nowrap;
      overflow: hidden;
      transition: .4s background-color ease; }
      .site-footer .newsletter-signup form button:hover {
        background-color: #dd1d12; }
      .site-footer .newsletter-signup form button:focus {
        outline: 0; }
    @media (min-width: 48em) and (max-width: 63.99em) {
      .site-footer .newsletter-signup {
        width: 32%; } }
    @media (max-width: 47.99em) {
      .site-footer .newsletter-signup {
        float: none;
        clear: both;
        width: 100%;
        margin-bottom: 10px; } }
  .site-footer .footer-nav {
    float: right;
    clear: right; }
    .site-footer .footer-nav:before,
    .site-footer .footer-nav:after {
      content: " ";
      display: table; }
    .site-footer .footer-nav:after {
      clear: both; }
    .site-footer .footer-nav ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      float: right;
      clear: right; }
      .site-footer .footer-nav ul:before,
      .site-footer .footer-nav ul:after {
        content: " ";
        display: table; }
      .site-footer .footer-nav ul:after {
        clear: both; }
    .site-footer .footer-nav li {
      float: left;
      margin-right: 20px; }
      .site-footer .footer-nav li:last-child {
        margin-right: 0; }
    .site-footer .footer-nav a {
      color: #fff;
      text-decoration: none;
      font-size: 11px; }
      .site-footer .footer-nav a:hover {
        text-decoration: underline; }
    @media (min-width: 48em) and (max-width: 63.99em) {
      .site-footer .footer-nav {
        max-width: 60%; }
        .site-footer .footer-nav ul {
          text-align: right; }
        .site-footer .footer-nav li {
          margin-right: 0;
          margin-left: 10px;
          float: none;
          display: inline-block; } }
    @media (max-width: 47.99em) {
      .site-footer .footer-nav {
        float: none;
        clear: both;
        width: 100%;
        margin-bottom: 10px; }
        .site-footer .footer-nav ul {
          float: none; }
        .site-footer .footer-nav li {
          margin-right: 10px; }
        .site-footer .footer-nav .social-links {
          position: relative;
          float: none;
          clear: both;
          margin-bottom: 0;
          top: auto;
          right: auto; } }
  .site-footer .copyright {
    width: 100%;
    float: left;
    clear: both;
    font-size: 10px;
    color: #fff;
    margin: 40px 0 0 0; }
    .site-footer .copyright a {
      color: #fff; }
      .site-footer .copyright a:hover {
        text-decoration: underline; }
    @media (max-width: 29.99em) {
      .site-footer .copyright {
        margin-top: 10px; } }

#darth-phader {
  height: 0;
  padding-top: 30.41667%;
  position: relative;
  width: 100%;
  border-bottom: 20px solid #ee3e34;
  background-color: #2b2d3a; }
  #darth-phader .slides,
  #darth-phader .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #darth-phader .slide {
    overflow: hidden; }
    #darth-phader .slide img {
      vertical-align: bottom;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      transform: translate(-50%, -50%); }
  #darth-phader .slide-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 200px);
    padding: 20px;
    font-size: 31px;
    line-height: 34px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    background-color: rgba(43, 45, 58, 0.86); }
    #darth-phader .slide-content p {
      margin: 0; }
    #darth-phader .slide-content span {
      font-weight: 700; }
  #darth-phader nav {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    height: 12px; }
    #darth-phader nav button {
      margin: 0 5px;
      width: 12px;
      height: 12px;
      border: 2px solid #fff;
      padding: 0;
      text-indent: 115%;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 50%;
      transition: .25s background-color ease;
      background-color: #2b2d3a; }
      #darth-phader nav button:hover {
        background-color: #15161d; }
      #darth-phader nav button.active {
        background-color: #ee3e34; }
      #darth-phader nav button:focus {
        outline: 0; }
  @media (min-width: 48em) and (max-width: 63.99em) {
    #darth-phader .slide-content {
      font-size: 24px;
      line-height: 26px;
      width: calc(100% - 8.333333%); } }
  @media (min-width: 30em) and (max-width: 47.99em) {
    #darth-phader {
      padding-top: 30.41667%; }
      #darth-phader .slide-content {
        font-size: 24px;
        line-height: 26px; } }
  @media (max-width: 47.99em) {
    #darth-phader {
      padding-top: 55.41667%; }
      #darth-phader .slide-content {
        font-size: 16px;
        line-height: 19px;
        padding: 10px;
        width: calc(100% - 20px); }
      #darth-phader nav {
        bottom: 10px; } }

#main {
  position: relative;
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 0;
  margin-bottom: 10px;
  border-top: 10px solid #ededed;
  max-width: 1200px; }
  @media (min-width: 48em) and (max-width: 63.99em) {
    #main {
      padding-left: 4.16667%;
      padding-right: 4.16667%;
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 47.99em) {
    #main {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      padding-bottom: 20px; } }

.services-list {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: #212332; }
  .services-list ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .services-list ul:before,
    .services-list ul:after {
      content: " ";
      display: table; }
    .services-list ul:after {
      clear: both; }
  .services-list li {
    float: left;
    width: 19.2%;
    height: 0;
    padding-top: 27.1%;
    margin-right: 1%;
    position: relative; }
    .services-list li:last-child {
      margin-right: 0; }
  .services-list a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-decoration: none;
    overflow: hidden; }
    .services-list a:hover .info,
    .services-list a:focus .info {
      bottom: 0; }
  .services-list img {
    vertical-align: bottom;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .services-list .info {
    position: absolute;
    height: 0;
    width: 100%;
    padding-top: 100%;
    bottom: -44.28044%;
    transition: .5s bottom ease;
    background-color: rgba(0, 0, 0, 0.8); }
    .services-list .info h2,
    .services-list .info .description,
    .services-list .info .read-more {
      padding-left: 10.41667%;
      padding-right: 10.41667%; }
    .services-list .info h2 {
      font-size: 18px;
      color: #fff;
      margin: 0 0 0;
      height: 70px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 16px;
      font-weight: 400; }
    .services-list .info .description {
      display: block;
      color: #fff;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 20px;
      position: absolute;
      left: 0;
      top: 36.90037%; }
    .services-list .info .read-more {
      display: block;
      color: #ee3e34;
      font-size: 14px;
      position: absolute;
      left: 0;
      bottom: 20px; }
  .services-list.landing {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff; }
    .services-list.landing li {
      width: 32.6%;
      padding-top: 32.6%;
      margin-right: 1.1%;
      margin-bottom: 10px; }
      .services-list.landing li:nth-of-type(3n+3) {
        margin-right: 0; }
    .services-list.landing .info {
      padding-top: 50%;
      bottom: -33.74233%; }
      .services-list.landing .info h2,
      .services-list.landing .info .description,
      .services-list.landing .info .read-more {
        padding-left: 6.13497%;
        padding-right: 6.13497%; }
  @media (min-width: 48em) and (max-width: 63.99em) {
    .services-list {
      padding-left: 4.16667%;
      padding-right: 4.16667%; }
      .services-list .info h2 {
        padding-top: 10px;
        font-size: 13px;
        line-height: 1.2; }
      .services-list .info .description {
        line-height: 1.1;
        font-size: 11px; }
      .services-list .info .read-more {
        bottom: 10px; } }
  @media (max-width: 47.99em) {
    .services-list {
      padding-left: 10px;
      padding-right: 10px; }
      .services-list li {
        width: 49%;
        padding-top: calc(50% - 50px);
        margin-right: 2%;
        margin-bottom: 10px; }
        .services-list li:nth-child(2n+2),
        .services-list li:last-child {
          margin-right: 0; }
        .services-list li .info {
          padding-top: 60%;
          bottom: -50%; }
          .services-list li .info h2 {
            font-size: 11px;
            line-height: 12px;
            padding-top: 8px;
            height: 50px; }
          .services-list li .info .description {
            display: none; }
          .services-list li .info .read-more {
            font-size: 11px;
            bottom: 10px; }
      .services-list.landing li:nth-child(2n+2) {
        margin-right: 1.1%; }
      .services-list.landing li .info .description {
        display: none; } }
  @media (min-width: 30em) and (max-width: 47.99em) {
    .services-list li .info {
      bottom: -52%; }
    .services-list.landing li .info h2 {
      padding-top: 6px; } }
  @media (max-width: 29.99em) {
    .services-list li .info h2 {
      padding-top: 6px; }
    .services-list li .info h2,
    .services-list li .info .description,
    .services-list li .info .read-more {
      padding-left: 10px;
      padding-right: 10px; }
    .services-list.landing li {
      width: 49%;
      padding-top: 50%;
      margin-right: 2%;
      margin-bottom: 10px; }
      .services-list.landing li:nth-child(2n+2) {
        margin-right: 0; }
      .services-list.landing li:nth-of-type(3n+3) {
        margin-right: 2%; }
      .services-list.landing li .info {
        bottom: -25%; }
        .services-list.landing li .info h2 {
          padding-top: 6px; }
        .services-list.landing li .info .decsription {
          display: block; } }

.services-list-text > ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch; }
  .services-list-text > ul li {
    flex: 0 1 32.6%;
    margin-bottom: 10px;
    margin-right: 1%;
    min-height: 130px; }
    .services-list-text > ul li:nth-child(3n) {
      margin-right: 0; }
  .services-list-text > ul a {
    display: block;
    height: 100%;
    position: relative;
    background-color: #2b2d3a;
    color: #fff;
    padding: 6.13497%;
    position: relative;
    transition: .4s background-color ease; }
    .services-list-text > ul a h2 {
      font-size: 18px;
      margin: 0 0 5px 0;
      color: #fff; }
    .services-list-text > ul a .description {
      font-size: 12px;
      margin-bottom: 50px;
      display: block; }
    .services-list-text > ul a .read-more {
      font-size: 14px;
      color: #ee3e34;
      position: absolute;
      bottom: 20px;
      left: 20px; }
    .services-list-text > ul a:hover {
      background-color: #ee3e34; }
      .services-list-text > ul a:hover .read-more {
        color: #2b2d3a; }

@media (max-width: 63.99em) {
  .services-list-text > ul a h2 {
    font-size: 16px; }
  .services-list-text > ul a .read-more {
    font-size: 12px; } }

@media (min-width: 30em) and (max-width: 47.99em) {
  .services-list-text > ul li {
    flex: 0 1 49.34896%;
    margin-right: 1.30208%; }
    .services-list-text > ul li:nth-child(3n) {
      margin-right: 1.30208%; }
    .services-list-text > ul li:nth-child(2n) {
      margin-right: 0; } }

@media (max-width: 29.99em) {
  .services-list-text > ul li {
    flex: 0 1 100%;
    margin-right: 0; }
    .services-list-text > ul li:nth-child(3n) {
      margin-right: 0; }
    .services-list-text > ul li:nth-child(2n) {
      margin-right: 0; } }

.content-area:before,
.content-area:after {
  content: " ";
  display: table; }

.content-area:after {
  clear: both; }

.content-area article {
  float: left;
  width: 60%; }
  .content-area article.latest-news {
    width: 100%;
    float: none;
    clear: both; }

.content-area aside {
  float: right;
  width: 32.6%; }
  .content-area aside img {
    vertical-align: bottom;
    width: 100%; }

.content-area.equal-split article,
.content-area.equal-split aside {
  width: 48.5%; }

@media (max-width: 29.99em) {
  .content-area article,
  .content-area aside {
    float: none;
    clear: both;
    width: 100%; }
  .content-area article {
    margin-bottom: 20px; }
  .content-area.equal-split article,
  .content-area.equal-split aside {
    width: 100%; } }

.recent-case-studies ul {
  list-style: none;
  padding: 0;
  margin: 0 auto; }
  .recent-case-studies ul:before,
  .recent-case-studies ul:after {
    content: " ";
    display: table; }
  .recent-case-studies ul:after {
    clear: both; }

.recent-case-studies li {
  float: left;
  margin-right: 1%;
  width: 32.6%;
  height: 0;
  padding-top: 21.8%;
  position: relative; }
  .recent-case-studies li:last-child {
    margin-right: 0; }

.recent-case-studies a {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden; }
  .recent-case-studies a:hover .info,
  .recent-case-studies a:focus .info {
    bottom: 0; }

.recent-case-studies img {
  vertical-align: bottom;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.recent-case-studies .info {
  position: absolute;
  left: 0;
  height: 0;
  padding-top: 66.87117%;
  width: 58.89571%;
  background-color: rgba(238, 62, 52, 0.9);
  bottom: -67.88991%;
  transition: .5s bottom ease; }
  .recent-case-studies .info h2,
  .recent-case-studies .info .description,
  .recent-case-studies .info .read-more {
    color: #fff;
    position: absolute;
    width: 100%; }
  .recent-case-studies .info h2 {
    height: 70px;
    top: 0;
    margin: 0;
    padding: 16px 15px 0;
    line-height: 1;
    font-size: 18px;
    font-weight: 400; }
  .recent-case-studies .info .description {
    top: 70px;
    padding: 0 15px; }
  .recent-case-studies .info .read-more {
    position: absolute;
    bottom: 10px;
    padding: 0 15px; }

@media (min-width: 30em) and (max-width: 63.99em) {
  .recent-case-studies .info {
    width: 100%; }
    .recent-case-studies .info h2 {
      font-size: 16px; }
    .recent-case-studies .info .description {
      font-size: 12px; } }

@media (max-width: 29.99em) {
  .recent-case-studies li {
    margin-right: 0;
    width: 100%;
    padding-top: 70%;
    margin-bottom: 10px; }
    .recent-case-studies li:last-of-type {
      margin-bottom: 0; }
  .recent-case-studies .info {
    width: 100%; }
    .recent-case-studies .info h2 {
      font-size: 16px; }
    .recent-case-studies .info .description {
      font-size: 12px; } }

.accreditations {
  padding-top: 60px;
  padding-bottom: 60px; }
  .accreditations ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
  .accreditations li {
    flex: 0 0 auto; }
    .accreditations li:last-child {
      margin-right: 0; }
  .accreditations img {
    vertical-align: bottom;
    width: 100%; }
  @media (max-width: 47.99em) {
    .accreditations {
      padding-top: 30px;
      padding-bottom: 10px; }
      .accreditations ul {
        justify-content: center; }
      .accreditations li {
        flex: 0 0 60px;
        margin: 0 10px 10px; } }

.latest-news ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.latest-news li {
  border-bottom: 1px solid #2b2d3a;
  position: relative; }
  .latest-news li a {
    display: block;
    height: 100%;
    position: relative;
    color: #2b2d3a;
    padding: 28px 2.8%;
    transition: .4s background-color ease; }
    .latest-news li a:hover {
      background-color: #2b2d3a; }
      .latest-news li a:hover .read-more {
        text-decoration: none; }
      .latest-news li a:hover time .time {
        color: #fff; }
      .latest-news li a:hover h3,
      .latest-news li a:hover .summary {
        color: #fff; }

.latest-news time {
  position: absolute;
  top: 28px;
  left: 2.8%;
  width: 70px;
  text-align: center; }
  .latest-news time .day,
  .latest-news time .month,
  .latest-news time .time {
    display: block;
    line-height: 1; }
  .latest-news time .day {
    font-size: 51px;
    color: #ee3e34; }
  .latest-news time .month {
    font-size: 13px;
    color: #ee3e34;
    margin-bottom: 2px; }
  .latest-news time .time {
    font-size: 14px; }

.latest-news .info {
  padding-left: 80px;
  position: relative; }

.latest-news h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px;
  padding-top: 10px;
  line-height: 1; }

.latest-news .summary {
  font-size: 12px;
  display: block;
  margin: 0 0 6px 0;
  line-height: 14px; }
  .latest-news .summary p {
    margin: 0; }

.latest-news .read-more {
  color: #ee3e34;
  display: block; }

@media (max-width: 29.99em) {
  .latest-news ul {
    margin-bottom: 10px; }
  .latest-news time {
    width: 55px; }
    .latest-news time .day {
      font-size: 36px; }
    .latest-news time .month {
      font-size: 11px; }
    .latest-news time .time {
      font-size: 11px; }
  .latest-news .info {
    padding-left: 65px; }
  .latest-news h3 {
    padding-top: 5px;
    font-size: 15px; }
  .latest-news .read-more {
    font-size: 11px; } }

.pagination {
  padding: 28px 2.8%; }
  .pagination .nav-links:before,
  .pagination .nav-links:after {
    content: " ";
    display: table; }
  .pagination .nav-links:after {
    clear: both; }
  .pagination .page-numbers {
    display: block;
    padding: 0 10px;
    float: left;
    font-weight: 700; }

.bradley-gallery {
  position: relative; }
  .bradley-gallery:before,
  .bradley-gallery:after {
    content: " ";
    display: table; }
  .bradley-gallery:after {
    clear: both; }
  .bradley-gallery .gallery-main-image {
    clear: both;
    margin-bottom: 15px; }
    .bradley-gallery .gallery-main-image img {
      vertical-align: bottom;
      width: 100%;
      height: auto; }
  .bradley-gallery .gallery-thumbs {
    list-style: none; }
    .bradley-gallery .gallery-thumbs:before,
    .bradley-gallery .gallery-thumbs:after {
      content: " ";
      display: table; }
    .bradley-gallery .gallery-thumbs:after {
      clear: both; }
    .bradley-gallery .gallery-thumbs li {
      width: 22.5%;
      float: left;
      margin: 0 3.125% 15px 0;
      padding-top: 22.5%;
      height: 0;
      overflow: visible;
      position: relative; }
      .bradley-gallery .gallery-thumbs li:nth-of-type(4n) {
        margin-right: 0; }
    .bradley-gallery .gallery-thumbs a {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden; }
      .bradley-gallery .gallery-thumbs a:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(238, 62, 52, 0.85);
        background-image: url(img/plus.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        opacity: 0;
        transition: .4s opacity ease; }
      .bradley-gallery .gallery-thumbs a:hover:after {
        opacity: 1; }
    .bradley-gallery .gallery-thumbs img {
      position: absolute;
      min-width: 100%;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      vertical-align: bottom; }
  .bradley-gallery .gallery-loading {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100px;
    height: 100px;
    background-color: #fff;
    opacity: 0;
    transition: 0.5s opacity ease, 0.5s z-index ease;
    z-index: -1; }
    .bradley-gallery .gallery-loading.active {
      opacity: 1;
      z-index: 100; }
    .bradley-gallery .gallery-loading:after {
      content: 'Please wait, image loading';
      display: block;
      width: 100%;
      height: 100%;
      background: url(img/loading.gif) 50% 50% no-repeat;
      background-size: 80px 80px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      text-indent: 110%;
      overflow: hidden;
      white-space: nowrap;
      transition: .5s z-index ease; }

/*http://tobiasahlin.com/spinkit/*/
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateY(0deg) rotateX(0deg); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }
